<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import pDebounce from 'p-debounce'

import trpc from '../lib/trpc'
import kagi from './kagi_summarizer_sample.txt?raw'

import Favicon from './Favicon.vue'
import ThermsWidget from './ThermsWidget.vue'
import LabelsPicker from './LabelsPicker.vue'

import type { LinkDetail, UserLinks, LabelsFilter, LinkWithLabels, Page } from 'server'

const { enableMarkingRead = true, ...props } = defineProps<{
  link: LinkDetail
  filter: LabelsFilter
  labels: UserLinks['labels']
  onUpdated: (data: LinkWithLabels & { isVisible?: boolean }) => void
  refresh: () => Promise<void>
  page: Page
  enableMarkingRead?: boolean
}>()

const route = useRoute()

const showSummary = ref(false)
const markLinkReadLoading = ref(false)
const markLinkUnreadLoading = ref(false)
const editing = ref(false)

const domain = computed(() => {
  return props.link.labels_all.filter((l) => l.startsWith('domain:'))[0].split(':')[1]
})

const domainUrl = computed(() => {
  const search = new URLSearchParams(route.query as Record<string, string>)
  search.set('domain', domain.value)
  search.delete('page')
  return `/?${search.toString()}`
})

const rescrapeLink = async () => {
  await trpc.link.rescrape.mutate({ linkId: props.link.link_id })
  await new Promise((resolve) => setTimeout(resolve, 3_000))
  await props.refresh()
}

const markLinkRead = async (linkId: string) => {
  markLinkReadLoading.value = true
  try {
    const data = await trpc.link.markRead.mutate({ linkId })
    props.onUpdated(data)
  } finally {
    markLinkReadLoading.value = false
  }
}

const markLinkUnread = async (linkId: string) => {
  markLinkUnreadLoading.value = true
  try {
    const data = await trpc.link.markUnread.mutate({ linkId })
    props.onUpdated(data)
  } finally {
    markLinkUnreadLoading.value = false
  }
}

const labelsFormValue = ref<string[]>([])
const labelsEditLoading = ref(false)

const setLabels = async (event: Event) => {
  event.preventDefault()
  labelsEditLoading.value = true
  try {
    const data = await trpc.overwriteLabels.mutate({
      linkId: props.link.link_id,
      labels: labelsFormValue.value,
      filter: props.filter,
      page: props.page
    })
    props.onUpdated(data)
    editing.value = false
  } finally {
    labelsEditLoading.value = false
  }
}
</script>

<template>
  <div class="shadow-md flex" :class="{ 'bg-gray-100': Boolean(props.link.read_at) }">
    <div class="contents"><slot name="sidebar" /></div>
    <div class="px-2 pt-2 flex-1">
      <div class="flex gap-x-2" :data-link-id="props.link.link_id">
        <div>
          <div class="flex">
            <span class="text-sm italic text-gray-700 mr-2">
              <Favicon :link="props.link" />
            </span>

            <a
              :href="props.link.url"
              class="link flex-1 overflow-hidden text-ellipsis"
              target="_blank"
              rel="noopener noreferrer external"
              @click="enableMarkingRead && markLinkRead(props.link.link_id)"
            >
              <!-- Instagram is an especially bad offender for long long titles,
              because they don't have titles. They just make the entrire post
              description the title. -->
              <div
                class="line-clamp-2"
                :class="{ 'font-semibold': !props.link.read_at }"
                :title="props.link.created_at.toISOString()"
              >
                <template v-if="props.link.status_code">
                  {{ props.link.title || 'No Title' }}
                </template>
                <template v-else>Retrieving...</template>
              </div>
            </a>
          </div>

          <div class="ml-6">
            <template v-if="!editing">
              <template v-if="props.link.labels_display.length">
                <div class="inline-flex flex-wrap gap-2 items-center">
                  <sl-icon-button name="pencil" class="text-xs" @click="editing = true" />
                  <span
                    v-for="label in props.link.labels_display"
                    :key="label"
                    class="text-sm text-gray-800"
                  >
                    {{ label }}
                  </span>
                </div>
              </template>
              <sl-button
                v-else
                class="text-xs -ml-3"
                @click="editing = true"
                variant="text"
                size="small"
              >
                <sl-icon name="pencil" class="text-black mr-2" />
                <span class="text-sm italic text-gray-700">No labels</span>
              </sl-button>
            </template>

            <form v-else @submit="setLabels">
              <LabelsPicker
                :labels="props.labels"
                :link="props.link"
                @update="labelsFormValue = $event"
              />

              <sl-button variant="primary" type="submit" class="mt-4" :loading="labelsEditLoading">
                Set Labels
              </sl-button>
            </form>
          </div>
        </div>

        <ul v-if="showSummary" class="list-disc list-inside p-4 bg-blue-100">
          <button type="button" @click="showSummary = false" class="float-right">
            <i class="bi-x-lg" />
          </button>
          <li v-for="line in kagi.trim().split('\n')" :key="line">
            {{ line }}
          </li>
        </ul>
      </div>

      <div class="flex items-center pb-1">
        <sl-icon-button
          v-if="props.link.read_at === null ? !markLinkReadLoading : !markLinkUnreadLoading"
          class="pr-2 [&::part(base)]:text-gray-700 text-lg"
          :class="{ invisible: !enableMarkingRead }"
          :name="props.link.read_at === null ? 'envelope' : 'envelope-paper'"
          @click="
            props.link.read_at === null
              ? markLinkRead(props.link.link_id)
              : markLinkUnread(props.link.link_id)
          "
        />
        <sl-spinner v-else class="mx-3" />

        <a :href="domainUrl" class="text-sm text-gray-600">
          {{ domain.replace(/^www\./, '') }}
        </a>

        <ThermsWidget
          :link="props.link"
          :filter="props.filter"
          :page="props.page"
          @update="onUpdated"
        />

        <sl-dropdown class="">
          <sl-icon-button slot="trigger" name="three-dots-vertical" />
          <sl-menu>
            <sl-menu-item>
              Added
              <sl-relative-time :date="props.link.created_at" />
              ( {{ props.link.created_at.toLocaleString() }})
            </sl-menu-item>

            <sl-menu-item value="paste" @click="rescrapeLink">Re-scrape</sl-menu-item>
          </sl-menu>
        </sl-dropdown>
      </div>
    </div>
  </div>
</template>
