import type { LinkDetail } from 'server'

export const sortLinks = (
  labels: Record<string, LinkDetail>,
  trashSorting = false
): LinkDetail[] => {
  if (!trashSorting) {
    return Object.values(labels)
      .toSorted((a, b) => (a.link_id > b.link_id ? -1 : 1))
      .toSorted((a, b) => b.therms_adjusted_at.getTime() - a.therms_adjusted_at.getTime())
      .toSorted((a, b) => b.therms - a.therms)
  } else {
    return Object.values(labels)
      .toSorted((a, b) => (a.read_at! > b.read_at! ? -1 : 1))
      .toSorted((a, b) => (a.trashed_at! > b.trashed_at! ? -1 : 1))
  }
}

export const sortLabels = (labels: Record<string, number>) =>
  Object.entries(labels)
    .toSorted(([, a], [, b]) => b - a)
    .map(([label, count]) => ({ label, count }))
