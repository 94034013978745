import * as Cookie from 'cookie'
import { ref } from 'vue'

import type { Ref } from 'vue'

type ClientVisibleSession = any

const baseCookieName = 'readstufflater-public-session'

export const pullSession = () => {
  const parsed = Cookie.parse(document.cookie)
  const cookieValue = parsed[`__Secure-${baseCookieName}`] ?? parsed[baseCookieName]
  if (!cookieValue) return null

  let parsedCookie = JSON.parse(cookieValue) as ClientVisibleSession
  parsedCookie = {
    ...parsedCookie,
    access_expires_at: parsedCookie.access_expires_at
      ? new Date(parsedCookie.access_expires_at as unknown as string)
      : null
  }
  session.value = parsedCookie
}

const session = ref(null) as Ref<ClientVisibleSession | null>
pullSession()
export default session
