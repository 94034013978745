<script setup lang="ts">
import { computed } from 'vue'

import type { LinkDetail } from 'server'

const faviconBaseUrl =
  import.meta.env.PROD === true
    ? 'f002.backblazeb2.com/file/bootspoon-readstufflater-favicons-prod'
    : 'f002.backblazeb2.com/file/bootspoon-readstufflater-favicons-dev'

const getFaviconUrl = (link: LinkDetail) =>
  link.favicon && `https://${faviconBaseUrl}/favicons_v2/${link.favicon}.png`

const props = defineProps<{ link: LinkDetail }>()
const faviconUrl = computed(() => getFaviconUrl(props.link))
</script>

<template>
  <img v-if="faviconUrl" :src="faviconUrl" aria-hidden class="h-4 w-4 rounded-sm inline-block" />
  <i v-else class="bi-globe" />
</template>
