import { createApp } from 'vue'
import { createPinia } from 'pinia'
import HoneybadgerVue from '@honeybadger-io/vue'

import PrimeVue from 'primevue/config'

// TODO: Break this into individual imports so we don't get a 500KB JS payload
import '@shoelace-style/shoelace'

import '@shoelace-style/shoelace/dist/themes/light.css'
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js'

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.16.0/cdn/')

import App from './App.vue'
import router from './router'

import './index.css'

let app = createApp(App).use(createPinia()).use(router).use(PrimeVue, { theme: 'none' })

if (import.meta.env.VITE_honeybadgerClientApiKey) {
  app = app.use(HoneybadgerVue, {
    apiKey: import.meta.env.VITE_honeybadgerClientApiKey,
    environment: 'production',
    revision: import.meta.env.GIT_REV
  })
}
app.mount('#app')
