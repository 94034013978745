<script setup lang="ts">
import { ref, computed } from 'vue'
import pDebounce from 'p-debounce'

import {
  IconTemperatureSnow,
  IconTemperatureSun,
  IconTemperature,
  IconPlus,
  IconMinus
} from '@tabler/icons-vue'

import trpc from '../lib/trpc'

import type { LinkDetail, LinkWithLabels, LabelsFilter, Page } from 'server'

const props = defineProps<{ link: LinkDetail; filter: LabelsFilter; page: Page }>()
const emit = defineEmits<{ update: [LinkWithLabels] }>()

const thermsLoading = ref(false)
const alterTherms = {
  delta: ref(0),
  _alter: pDebounce(async () => {
    thermsLoading.value = true
    try {
      const data = await trpc.alterTherms.mutate({
        linkId: props.link.link_id,
        filter: props.filter,
        page: props.page,
        delta: alterTherms.delta.value
      })
      alterTherms.delta.value = 0
      emit('update', data)
    } finally {
      thermsLoading.value = false
    }
  }, 500),
  alter: async (change: { delta: number }) => {
    alterTherms.delta.value += change.delta
    await alterTherms._alter()
  }
}

const alteredTherms = computed(() => props.link.therms + alterTherms.delta.value)
</script>

<template>
  <div
    class="flex items-center ml-auto rounded-lg"
    :class="{
      'bg-orange-50': alteredTherms > 0,
      'bg-cyan-50': alteredTherms < 0,
      'bg-gray-100': alteredTherms === 0
    }"
  >
    <button
      size="small"
      @click="alterTherms.alter({ delta: 1 })"
      class="text-xl text-orange-400 px-2 py-1"
    >
      <IconPlus />
    </button>

    <div v-if="!thermsLoading" class="flex items-center">
      <span
        class="text-black font-semibold"
        :class="{
          'text-orange-900': alteredTherms > 0,
          'text-cyan-900': alteredTherms < 0,
          'text-grey-900': alteredTherms === 0,
          // The neutral temperature icon has the same image width but less
          // visual content so its alignment doesn't match the warm/cold
          // temp icons
          '-mr-1.5': alteredTherms === 0
        }"
        _style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2)"
      >
        {{ alteredTherms }}
      </span>
      <IconTemperatureSun v-if="alteredTherms > 0" class="text-orange-500" />
      <IconTemperatureSnow v-else-if="alteredTherms < 0" class="text-cyan-500" />
      <IconTemperature v-else class="text-gray-400" :class="[alteredTherms === 0 && '-mr-1.5']" />
    </div>
    <sl-spinner v-else />

    <button
      size="small"
      @click="alterTherms.alter({ delta: -1 })"
      class="text-xl text-cyan-400 px-2 py-1"
    >
      <IconMinus />
    </button>
  </div>
</template>
