<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'

import Drawer from 'primevue/drawer'

const model = defineModel<boolean>()

const showSidebar = useMediaQuery('(min-width: 640px)')
</script>

<template>
  <Drawer header="ReadStuffLater" v-model:visible="model" v-if="!showSidebar">
    <slot />
  </Drawer>
  <slot v-else />
</template>
