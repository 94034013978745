import { createRouter, createWebHistory } from 'vue-router'
import type { NavigationGuard } from 'vue-router'
import Home from '../pages/Home.vue'
import session, { pullSession } from '../lib/session'

const requireAuthz: NavigationGuard = (_to, _from) => {
  pullSession()
  if (!session.value) {
    console.log('redirecting to login')
    return { name: 'login' }
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home,
      beforeEnter: requireAuthz
    },
    {
      name: 'triage',
      path: '/triage',
      component: () => import('../pages/Triage.vue'),
      beforeEnter: requireAuthz
    },
    {
      name: 'after-share',
      path: '/after-share',
      component: () => import('../pages/AfterShare.vue'),
      beforeEnter: [
        requireAuthz,
        (to, _from) => {
          if (!('linkId' in to.query)) {
            console.error('Missing linkId query param')
            return { name: 'error' }
          }
        }
      ]
    },
    {
      name: 'settings',
      path: '/settings',
      component: () => import('../pages/Settings.vue'),
      beforeEnter: requireAuthz
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../pages/Login.vue')
    },
    {
      name: 'signup',
      path: '/signup',
      component: () => import('../pages/Signup.vue')
    },
    {
      name: 'inbox-help',
      path: '/help',
      component: () => import('../pages/InboxHelp.vue')
    },
    {
      name: 'error',
      path: '/error',
      component: () => import('../pages/Error.vue')
    }
    /*
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../errors/404.vue')
    }
    */
  ]
})

export default router
