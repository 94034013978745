import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import superjson from 'superjson'

import session, { pullSession } from './session'

import type { AppRouter } from 'server'

const client = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: '/trpc',
      fetch: async (...args) => {
        try {
          const response = await fetch(...args)
          return response
        } finally {
          session.value = pullSession
        }
      }
    })
  ],
  transformer: superjson
})
export default client
